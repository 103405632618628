/* eslint-disable @typescript-eslint/ban-ts-comment */

// import log from "~/helpers/logger";

// import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { I18nContext, I18nManager } from "@shopify/react-i18n";
import { GadgetWrapperProvider } from "./components/GadgetWrapperProvider";
import { PolarisProvider } from "./components/PolarisProvider";

import { useGadget } from "@gadgetinc/react-shopify-app-bridge";

// import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import LoadingSkeletonPage from "~/components/LoadingSkeletonPage";

// import routes from "./Routes";
// import notionQuery from "./queries/notionQuery";

import "./App.css";
import React, { Suspense } from "react";

import * as Sentry from "@sentry/react";
import log from "~/helpers/logger";

// import { ErrorPage } from "./pages/error";
// import Help, { loader as helpLoader }  from "./pages/help";

// import LoadedEmbeddedGadgetApp from "./LoadedEmbeddedGadgetApp";
const LoadedEmbeddedGadgetApp = React.lazy(
  () => import("./LoadedEmbeddedGadgetApp")
);

// See: https://github.com/Shopify/polaris/discussions/8300#discussioncomment-5012684
const localeFromUrl = new URLSearchParams(location.search).get("locale");
// console.log("🌐 > localeFromUrl:", localeFromUrl);
const locale = localeFromUrl || window?.navigator?.language || "en";
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
log.info("🌐 > { locale, timezone }:", { locale, timezone });

const i18nManager = new I18nManager({
  locale,
  fallbackLocale: "en",
  timezone,
  onError(error) {
    log.error("i18nManager > onError > error:", error);
    // throw error;
  },
});

function App() {
  return (
    <I18nContext.Provider value={i18nManager}>
      <PolarisProvider>
        <GadgetWrapperProvider>
          {/* <QueryClientProvider client={queryClient}> */}
          <EmbeddedGadgetApp />
          {/* <ReactQueryDevtools position="bottom-right" /> */}
          {/* </QueryClientProvider> */}
        </GadgetWrapperProvider>
      </PolarisProvider>
    </I18nContext.Provider>
  );
}

function EmbeddedGadgetApp() {
  // See: https://docs.gadget.dev/guides/connections/shopify-app-frontends#the-usegadget-react-hook
  // isAuthenticated: boolean; // 'true' if the user has completed a successful OAuth flow
  // isEmbedded: boolean; // 'true' if the app is running in an embedded context
  // isRootFrameRequest: boolean; // 'true' if a user is viewing a "type: AppType.Embedded" app in a non-embedded context, for example, accessing the app at a hosted Vercel domain
  // loading: boolean; // 'true' if the OAuth flow is in process
  const { isAuthenticated, isEmbedded, isRootFrameRequest, loading } =
    useGadget();

  // log.debug(
  //   "🔎 > EmbeddedApp > { isAuthenticated, isEmbedded, isRootFrameRequest, loading }:",
  //   { isAuthenticated, isEmbedded, isRootFrameRequest, loading }
  // );

  // Use `isAuthenticated` to render elements that require app bridge once the OAuth flow is complete
  const stillLoading = isRootFrameRequest || !isAuthenticated || loading;

  if (stillLoading) {
    return (
      <LoadingSkeletonPage isEmbedded isRootFrameRequest isAuthenticated />
    );
  } else {
    // Testing loading state:
    // return (
    //   <LoadingSkeletonPage isEmbedded isRootFrameRequest isAuthenticated />
    // );
    //
    // Extracted to a separate component to avoid hooks issue with conditional return:
    // https://github.com/facebook/react/issues/24391#issuecomment-1131531946
    return (
      <Sentry.ErrorBoundary
        fallback={
          <LoadingSkeletonPage
            isEmbedded
            isRootFrameRequest
            isAuthenticated
            isError={true}
          />
        }
      >
        <Suspense
          fallback={
            <LoadingSkeletonPage
              isEmbedded
              isRootFrameRequest
              isAuthenticated
            />
          }
        >
          <LoadedEmbeddedGadgetApp />
        </Suspense>
      </Sentry.ErrorBoundary>
    );
  }
}

export default App;
