import log from "~/helpers/logger";

import { useEffect, useState } from "react";

import { Loading } from "@shopify/app-bridge-react";

import * as Sentry from "@sentry/browser";

import {
  Banner,
  BlockStack,
  InlineGrid,
  Layout,
  Page,
  SkeletonBodyText,
  SkeletonPage,
} from "@shopify/polaris";

import LoadingSkeletonCard from "~/components/polaris/LoadingSkeletonCard";

export default function LoadingSkeletonPage({
  isEmbedded,
  isRootFrameRequest,
  isAuthenticated,
  insideAppBridge,
  isError,
} = props) {
  const [showRootFrameRequestWarning, setShowRootFrameRequestWarning] =
    useState(false);
  const [showReloadButton, setShowReloadButton] = useState(
    !!isError ? true : false
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      const isInShopifyAdmin = !isRootFrameRequest || window !== window.parent;

      if (isEmbedded && isInShopifyAdmin) {
        log.error("Stuck on loading skeleton page for more than 10 seconds");
        setShowReloadButton(true);
      } else {
        setShowRootFrameRequestWarning(true);
      }
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {/* Only use the Loading component if we're inside the App Bridge provider and we have an app instance. */}
      {insideAppBridge && <Loading />}

      {showRootFrameRequestWarning && (
        <Page fullWidth>
          <Layout>
            <Layout.Section>
              <Banner tone="warning">
                Sorry, this app only works when embedded in the Shopify admin.
              </Banner>
            </Layout.Section>
          </Layout>
        </Page>
      )}

      {showReloadButton && (
        <Page fullWidth>
          <Layout>
            <Layout.Section>
              <Banner
                action={{
                  content: "Reload",
                  onAction: () => {
                    log.warn(
                      "Reloading loading skeleton page via button press"
                    );
                    window.location.reload();
                  },
                }}
                // Enable contact support option
                secondaryAction={{
                  content: "Contact support",
                  onAction: () => {
                    log.debug("Contact support");
                    const eventId = Sentry.lastEventId();
                    Sentry.showReportDialog({
                      eventId,
                      title: "It looks like we're having some issues... 😵‍💫",
                    });
                  },
                }}
                tone="warning"
              >
                Sorry, it looks like something has gone wrong. Try reloading the
                page, or use the Contact Support button if the problem persists.
              </Banner>
            </Layout.Section>
          </Layout>
        </Page>
      )}

      <SkeletonPage title="Koha - Charity Donations & Tax Receipts | Loading...">
        <BlockStack gap="400">
          <InlineGrid columns={{ xs: 1, md: "1fr" }} gap="400">
            <BlockStack gap="400">
              <Banner title="Loading" tone="info">
                <SkeletonBodyText lines={1} />
              </Banner>
            </BlockStack>
          </InlineGrid>

          <InlineGrid columns={{ xs: 1, md: "1fr 1fr" }} gap="400">
            {/* Left side */}
            <BlockStack gap="400">
              <LoadingSkeletonCard />
              <LoadingSkeletonCard />
              <LoadingSkeletonCard />
            </BlockStack>
            {/* Right side */}
            <BlockStack gap={{ xs: "400", md: "400" }}>
              <LoadingSkeletonCard />
              <LoadingSkeletonCard />
              <LoadingSkeletonCard />
            </BlockStack>
          </InlineGrid>

          <InlineGrid columns={{ xs: 1, md: "1fr" }} gap="400">
            <BlockStack gap="400">
              <LoadingSkeletonCard />
            </BlockStack>
          </InlineGrid>
        </BlockStack>
      </SkeletonPage>
    </>
  );
}
