import { Box } from "@shopify/polaris";

export default function SkeletonLabel(props) {
  return (
    <Box
      background="bg-fill-tertiary"
      minHeight="1rem"
      maxWidth="5rem"
      borderRadius="base"
      {...props}
    />
  );
}
