import {
  BlockStack,
  Box,
  Button,
  Card,
  SkeletonBodyText,
} from "@shopify/polaris";

import SkeletonLabel from "~/components/polaris/SkeletonLabel";

const LoadingSkeletonCard = () => {
  return (
    <Card roundedAbove="sm">
      <BlockStack gap="400">
        <SkeletonLabel />
        <Box border="divider" borderRadius="base" />
        <SkeletonBodyText lines={1} />
        <Button disabled></Button>
      </BlockStack>
    </Card>
  );
};

export default LoadingSkeletonCard;
