// Disabled until workaround for Gadget Provider not being able to pass through a `router` prop at present fixed
// import { useMemo } from "react";
// import { useLocation, useNavigate } from "react-router-dom";

import { Banner, Layout, Page } from "@shopify/polaris";

import {
  AppType,
  Provider as GadgetProvider,
} from "@gadgetinc/react-shopify-app-bridge";

import { api } from "~/api";

/**
 * A component to configure Gadget Provider.
 *
 * Based on: https://github.com/Shopify/shopify-frontend-template-react/blob/main/components/providers/AppBridgeProvider.jsx
 */
export function GadgetWrapperProvider({ children }) {
  //// Start: Disabled until workaround for Gadget Provider not being able to pass through a `router` prop at present fixed ////
  // See: https://github.com/gadget-inc/js-clients/issues/103
  // Based on code in: https://shopify.dev/apps/tools/app-bridge/getting-started/using-react#using-a-custom-router
  /*
  const location = useLocation();
  const navigate = useNavigate();

  const history = useMemo(
    () => ({
      replace: (path) => {
        navigate(path, { replace: true });
      },
    }),
    [navigate]
  );
  */
  //// End: Disabled until workaround for Gadget Provider not being able to pass through a `router` prop at present fixed ////

  if (!process.env.SHOPIFY_API_KEY) {
    const bannerProps = {
      title: "Missing Shopify API Key",
      children: (
        <>
          Your app is running without the SHOPIFY_API_KEY environment variable.
          Please ensure that it is set when running or building your React app.
        </>
      ),
    };

    return (
      <Page narrowWidth>
        <Layout>
          <Layout.Section>
            <div style={{ marginTop: "100px" }}>
              <Banner {...bannerProps} status="critical" />
            </div>
          </Layout.Section>
        </Layout>
      </Page>
    );
  }

  return (
    <GadgetProvider
      type={AppType.Embedded}
      shopifyApiKey={process.env.SHOPIFY_API_KEY}
      api={api}
      //// Disabled until workaround for Gadget Provider not being able to pass through a `router` prop at present fixed
      // router={routerConfig}
    >
      {children}
    </GadgetProvider>
  );
}
