import React from "react";
import { createRoot } from "react-dom/client";

import * as Sentry from "@sentry/react";

import App from "./App";

import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

// Setup Sentry
Sentry.init({
  dsn: "https://34e3d1bbc3da697009cf0e7c6ad74bec@o4504142788100096.ingest.sentry.io/4506337315586048",
  integrations: [
    new CaptureConsoleIntegration({
      // array of methods that should be captured
      // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
      levels: ["warn", "error"],
    }),
    new Sentry.BrowserTracing(),
    new Sentry.Replay(),
  ],

  environment: process.env.SENTRY_ENVIRONMENT || process.env.NODE_ENV,

  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById("root");
const root = createRoot(container);

// Note, wrapping in StrictMode may cause dual renders in the router
// Won't happen in prod bundle though
// See: https://github.com/remix-run/react-router/issues/8968#issuecomment-1152570206
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
