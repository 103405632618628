import { useCallback } from "react";
import { useNavigate } from "@shopify/app-bridge-react";
import { AppProvider } from "@shopify/polaris";
import { I18nContext, I18nManager, useI18n } from "@shopify/react-i18n";
import shopifyPolarisTranslations_en from "@shopify/polaris/locales/en.json";
import "@shopify/polaris/build/esm/styles.css";

import appTranslations_en from "~/translations/en.json";

function AppBridgeLink({ url, children, external, ...rest }) {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    // log.debug("AppBridgeLink:handleClick", url);
    navigate(url);
  }, [url]);

  const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;

  if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
    return (
      <a target="_blank" rel="noopener noreferrer" href={url} {...rest}>
        {children}
      </a>
    );
  }

  // Needs the onClick handler *after* spreading `...rest`, or else doesn't work in ResourceList
  // Also, don't use a React Router <Link> as that doesn't work with ContextualSaveBar leaveConfirmationDisable setting
  // Not sure if `data-primary-link={true}` is required or not...
  return (
    <a {...rest} onClick={handleClick} data-primary-link={true}>
      {children}
    </a>
  );
}

/**
 * Sets up the AppProvider from Polaris.
 * @desc PolarisProvider passes a custom link component to Polaris.
 * The Link component handles navigation within an embedded app.
 * Prefer using this vs any other method such as an anchor.
 * Use it by importing Link from Polaris, e.g:
 *
 * ```
 * import {Link} from '@shopify/polaris'
 *
 * function MyComponent() {
 *  return (
 *    <div><Link url="/tab2">Tab 2</Link></div>
 *  )
 * }
 * ```
 *
 * PolarisProvider also passes translations to Polaris.
 *
 */
export function PolarisProvider({ children }) {
  let i18n;
  // Register our app translations with the i18n manager
  [i18n] = useI18n({
    id: "App",
    fallback: appTranslations_en,
  });

  // Register the default Polaris translations with the i18n manager
  [i18n] = useI18n({
    id: "Polaris",
    fallback: shopifyPolarisTranslations_en,
    // See: https://polaris.shopify.com/components/utilities/app-provider?example=app-provider-with-i18n
    // translations(locale) {
    //   return import(
    //     /* webpackChunkName: "Polaris-i18n", webpackMode: "lazy-once" */ `@shopify/polaris/locales/${locale}.json`
    //   ).then((dictionary) => dictionary && dictionary.default);
    // },

    // Alternative approach, see: https://github.com/Shopify/polaris/issues/3016#issuecomment-774934958
    // async translations(locale) {
    //   const dictionary = await import(
    //     /* webpackChunkName: "Polaris-i18n", webpackMode: "lazy-once" */ `@shopify/polaris/locales/${locale}.json`
    //   );

    //   return dictionary.default;
    // },
  });

  // i18n.translations is an array of translation dictionaries, where the first
  // dictionary is the desired language, and the second is the fallback.
  // See: https://polaris.shopify.com/components/utilities/app-provider?example=app-provider-with-i18n
  // Alternative approach, see: https://github.com/Shopify/polaris/issues/3016#issuecomment-774934958
  // <AppProvider i18n={i18n.translations.slice().reverse()}>
  return (
    <AppProvider i18n={i18n.translations} linkComponent={AppBridgeLink}>
      {children}
    </AppProvider>
  );
}
