import * as loglevel from "loglevel";

const log = loglevel.getLogger("Default");
log.setLevel(log.levels.TRACE);

// Set correct `debug` output as console.debug:
// See: https://github.com/pimterry/loglevel/issues/126#issuecomment-433131539
let originalFactory = log.methodFactory;
log.methodFactory = function (methodName, logLevel, loggerName) {
  if (methodName !== "debug") {
    return originalFactory(methodName, logLevel, loggerName);
  } else {
    return console.debug;
  }
};
log.setLevel(log.getLevel()); // Be sure to call setLevel method in order to apply plugin
// End: Set correct `debug` output as console.debug

// log.debug("Loglevel initialized");

// log.debug("Debug log 1");
// log.info("Info log 1");
// log.warn("Warn log 1");
// log.error(new Error("Test 1"), "Error log 1");

export default log;
